<script setup lang="ts">
import { computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { getFutureAndLiveMatches } from '@/gql/requests'
import { useUserStore } from '@/stores'
import BannerLuna from './BannerLuna.vue'
// import SuccessTracker from '@/components/SuccessTracker.vue'
import SuccessTracker from '@/components/SuccessTracker/SuccessTracker.vue'
import MatchesLive from '@/components/Matches/MatchesLive.vue'
import MatchesFuture from '@/components/Matches/MatchesFuture.vue'
import MatchesBest from '@/components/Matches/MatchesBest.vue'
import Reviews from '@/components/Reviews.vue'
import WhatYouGet from './WhatYouGet/WhatYouGet.vue'
import PromoBanner from '@/components/PromoBanner.vue'
import WhereBegin2 from './WhereBegin2.vue'

const userStore = useUserStore()

const { result } = useQuery(getFutureAndLiveMatches)

const showMatchesFuture = computed(() => {
   const matches = result.value?.matches
   return matches?.some((match) => match.status === 'UPCOMING')
})
</script>

<template>
   <div class="grid gap-y-48px <laptop:gap-y-32px">
      <BannerLuna v-if="userStore.userUnReg" class="<laptop:mx-16px" />
      <PromoBanner v-if="userStore.userUnReg" class="hidden <laptop:block <laptop:mx-16px" />
      <SuccessTracker />
      <WhatYouGet v-if="userStore.userUnReg" class="<laptop:mx-16px" />
      <WhereBegin2 v-if="userStore.user && !userStore.user.onboarding.collapsed" />
      <MatchesLive />
      <MatchesFuture v-if="showMatchesFuture" />
      <MatchesBest class="<laptop:px-16px" />
      <Reviews v-if="userStore.userUnReg" :column="4" />
   </div>
</template>
