<script setup lang="ts">
import { watch } from 'vue'
import { i18nRoute, locale } from '@/i18n'
import { useRoute, RouterView } from 'vue-router'
import { useUserStore, useGlobalStore } from '@/stores'
import { getUser, getFutureAndLiveMatches, subscribeUser, subscribeMatches, notify, NotifyType, NotifyCategory } from '@/gql/requests'
import { useQuery, useSubscription } from '@vue/apollo-composable'
import dayjs from 'dayjs'
import { startConnection } from '@/composables/socket-io'
import * as amplitude from '@/composables/amplitude'

window.addEventListener('vite:preloadError', () => {
   window.location.reload()
})

amplitude.init()

const route = useRoute()

const userStore = useUserStore()
const globalStore = useGlobalStore()

const { onResult, onError, subscribeToMore: subscribeToMoreUser } = useQuery(getUser)
const { onResult: onResultNotify } = useSubscription(notify)

onResultNotify((data) => {
   console.log(data)
})

onResult(({ data, loading }) => {
   const user = data?.user

   userStore.setLoading(loading)

   if (user) {
      userStore.setUser(user)
      amplitude.track('userAuthorized', 'site')

      subscribeToMoreUser({
         document: subscribeUser,
         updateQuery: (prev, { subscriptionData }) => {
            const user = subscriptionData.data.userUpdated

            if (user) {
               userStore.setUser(user)
            }

            return prev
         },
         onError: (data) => {
            console.log(data)
         }
      })
   }
})

onError(() => {
   userStore.setLoading(false)
})

const { subscribeToMore, onResult: onResultMatches } = useQuery(getFutureAndLiveMatches)

onResultMatches(({ data }) => {
   const matches = data?.matches
   if (matches) {
      const matchesFiltered = matches.filter((match) => {
         return new Date(match.date).getTime() < Date.now() + 1000 * 60 * 10
      })
      const matchesInfo = matchesFiltered.map((match) => match.id)
      startConnection(matchesInfo)
   }
})

subscribeToMore({
   document: subscribeMatches
})

watch(
   () => globalStore.screenLock,
   (value) => {
      if (value) {
         document.querySelector('#app')?.classList.add('lock')
      } else {
         document.querySelector('#app')?.classList.remove('lock')
      }
   }
)
</script>

<template>
   <component :is="route.meta.layoutComponent">
      <RouterView />
   </component>
   <Teleport to="body">
      <div
         class="fixed top-16px bottom-16px left-16px w-260px z-11 pointer-events-none flex flex-col justify-end gap-y-12px text-12px leading-14px <laptop:left-1/2 <laptop:-translate-x-1/2 <laptop:justify-start"
      >
         <template v-for="(notification, index) in globalStore.notifications" :key="index">
            <Transition
               appear
               enter-from-class="opacity-0 -translate-x-20px"
               enter-active-class="duration-500 transition-all"
               leave-active-class="duration-500 transition-all"
               leave-to-class="opacity-0 translate-x-0"
            >
               <div
                  v-if="notification.type === 'error' || notification.type === 'success'"
                  class="min-h-68px max-h-100px gradient-plate-900 rounded-10px shadow-black2 border border-gray-50/4 relative z-1 px-12px py-8px flex items-center gap-x-8px text-gray-50/80"
               >
                  <img
                     v-if="notification.type === 'error'"
                     src="/img/notifications/error.png"
                     srcset="/img/notifications/error@2.png 2x"
                     alt="error"
                     class="w-28px"
                  />
                  <img
                     v-else-if="notification.type === 'success'"
                     src="/img/notifications/success.png"
                     srcset="/img/notifications/success@2.png 2x"
                     alt="success"
                     class="w-28px"
                  />

                  <span class="break-words hyphens-auto line-clamp-6">{{ notification.text }}</span>

                  <div class="h-2px gradient-lava-700 absolute left-1/2 -translate-x-1/2 bottom-0 opacity-30 rounded-full w-90%"></div>
               </div>
            </Transition>
            <Transition
               appear
               enter-from-class="opacity-0 -translate-x-20px"
               enter-active-class="duration-500 transition-all"
               leave-active-class="duration-500 transition-all"
               leave-to-class="opacity-0 translate-x-0"
            >
               <router-link
                  v-if="notification.type === NotifyType.Match && notification.category === NotifyCategory.Status"
                  :to="i18nRoute({ name: 'match', params: { id: notification.matchId } })"
                  class="h-68px gradient-plate-900-reverse rounded-10px shadow-black2 border border-gray-50/4 relative z-1 overflow-hidden pointer-events-auto px-8px flex flex-col justify-center"
               >
                  <div class="flex content-center gap-x-4px">
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team1.logo" alt="team1" class="h-20px -mt-4px" />
                        {{ notification.team1.name }}
                     </div>

                     vs.
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team2.logo" alt="team2" class="h-20px -mt-4px" />
                        {{ notification.team2.name }}
                     </div>
                  </div>

                  <div class="mt-4px">
                     {{ $t('M_lMvua4o5hbGx_0hufoD') }} <span class="text-red-700 font-bold uppercase">{{ notification.status }}</span>
                  </div>

                  <img
                     src="/img/notifications/status.png"
                     srcset="/img/notifications/status@2.png 2x"
                     alt="date"
                     class="absolute top-1/2 -translate-y-1/2 right-0 opacity-10 w-100px -z-1"
                  />
                  <div class="h-2px gradient-lava-700 absolute left-8px right-8px bottom-0 opacity-30 rounded-full"></div>
               </router-link>
            </Transition>
            <Transition
               appear
               enter-from-class="opacity-0 -translate-x-20px"
               enter-active-class="duration-500 transition-all"
               leave-active-class="duration-500 transition-all"
               leave-to-class="opacity-0 translate-x-0"
            >
               <router-link
                  v-if="notification.type === NotifyType.Match && notification.category === NotifyCategory.Predict"
                  :to="i18nRoute({ name: 'match', params: { id: notification.matchId } })"
                  class="h-68px gradient-plate-900-reverse rounded-10px shadow-black2 border border-gray-50/4 relative z-1 overflow-hidden pointer-events-auto px-8px flex flex-col justify-center"
               >
                  <div class="flex content-center gap-x-4px">
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team1.logo" alt="team1" class="h-20px -mt-4px" />
                        {{ notification.team1.name }}
                     </div>

                     vs.
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team2.logo" alt="team2" class="h-20px -mt-4px" />
                        {{ notification.team2.name }}
                     </div>
                  </div>

                  <div class="mt-4px">
                     {{ $t('p9eyo-VhDZK9XA3zXyMrw') }}
                     <br />
                     <template v-if="true">
                        <span class="text-red-700 text-bold">{{ $t('UwfJZ95QbBqz7Ufs6RSQ-') }}</span> {{ $t('Cb_PDTsjEfavsEUvwpx75') }}
                     </template>
                  </div>

                  <img
                     src="/img/notifications/predict.png"
                     srcset="/img/notifications/predict@2.png 2x"
                     alt="date"
                     class="absolute top-1/2 -translate-y-1/2 right-0 opacity-10 w-100px -z-1"
                  />
                  <div class="h-2px gradient-lava-700 absolute left-8px right-8px bottom-0 opacity-30 rounded-full"></div>
               </router-link>
            </Transition>
            <Transition
               appear
               enter-from-class="opacity-0 -translate-x-20px"
               enter-active-class="duration-500 transition-all"
               leave-active-class="duration-500 transition-all"
               leave-to-class="opacity-0 translate-x-0"
            >
               <router-link
                  v-if="notification.type === NotifyType.Match && notification.category === NotifyCategory.Date"
                  :to="i18nRoute({ name: 'match', params: { id: notification.matchId } })"
                  class="h-68px gradient-plate-900-reverse rounded-10px shadow-black2 border border-gray-50/4 relative z-1 overflow-hidden pointer-events-auto px-8px flex flex-col justify-center"
               >
                  <div class="flex content-center gap-x-4px">
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team1.logo" alt="team1" class="h-20px -mt-4px" />
                        {{ notification.team1.name }}
                     </div>

                     vs.
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team2.logo" alt="team2" class="h-20px -mt-4px" />
                        {{ notification.team2.name }}
                     </div>
                  </div>

                  <div class="mt-4px">
                     {{ $t('hwXaQVS-EX_HRns27GOjQ') }}
                     <span class="text-red-700">{{ dayjs(notification.dateStart).locale(locale).format('D MMMM, HH:mm') }}</span>
                  </div>

                  <img
                     src="/img/notifications/date.png"
                     srcset="/img/notifications/date@2.png 2x"
                     alt="date"
                     class="absolute top-1/2 -translate-y-1/2 right-0 opacity-10 w-100px -z-1"
                  />
                  <div class="h-2px gradient-lava-700 absolute left-8px right-8px bottom-0 opacity-30 rounded-full"></div>
               </router-link>
            </Transition>
            <Transition
               appear
               enter-from-class="opacity-0 -translate-x-20px"
               enter-active-class="duration-500 transition-all"
               leave-active-class="duration-500 transition-all"
               leave-to-class="opacity-0 translate-x-0"
            >
               <router-link
                  v-if="notification.type === NotifyType.Match && notification.category === NotifyCategory.MapPick"
                  :to="i18nRoute({ name: 'match', params: { id: notification.matchId } })"
                  class="h-68px gradient-plate-900-reverse rounded-10px shadow-black2 border border-gray-50/4 relative z-1 overflow-hidden pointer-events-auto px-8px flex flex-col justify-center"
               >
                  <div class="flex content-center gap-x-4px">
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team1.logo" alt="team1" class="h-20px -mt-4px" />
                        {{ notification.team1.name }}
                     </div>

                     vs.
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team2.logo" alt="team2" class="h-20px -mt-4px" />
                        {{ notification.team2.name }}
                     </div>
                  </div>

                  <div class="mt-4px">{{ $t('HcyICH6O4FeNvhNKOlxpJ') }}</div>

                  <img
                     src="/img/notifications/map.png"
                     srcset="/img/notifications/map@2.png 2x"
                     alt="date"
                     class="absolute top-1/2 -translate-y-1/2 right-0 opacity-10 w-100px -z-1"
                  />
                  <div class="h-2px gradient-lava-700 absolute left-8px right-8px bottom-0 opacity-30 rounded-full"></div>
               </router-link>
            </Transition>
            <Transition
               appear
               enter-from-class="opacity-0 -translate-x-20px"
               enter-active-class="duration-500 transition-all"
               leave-active-class="duration-500 transition-all"
               leave-to-class="opacity-0 translate-x-0"
            >
               <router-link
                  v-if="notification.type === NotifyType.Match && notification.category === NotifyCategory.TeamChanged"
                  :to="i18nRoute({ name: 'match', params: { id: notification.matchId } })"
                  class="h-68px gradient-plate-900-reverse rounded-10px shadow-black2 border border-gray-50/4 relative z-1 overflow-hidden pointer-events-auto px-8px flex flex-col justify-center"
               >
                  <div class="flex content-center gap-x-4px">
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team1.logo" alt="team1" class="h-20px -mt-4px" />
                        {{ notification.team1.name }}
                     </div>

                     vs.
                     <div class="text-yellow-700 flex item-start gap-x-3px">
                        <img :src="notification.team2.logo" alt="team2" class="h-20px -mt-4px" />
                        {{ notification.team2.name }}
                     </div>
                  </div>

                  <div class="mt-4px">{{ $t('xDRRT7fYlQXSd_8Hsfrbd') }}</div>

                  <img
                     src="/img/notifications/replacement.png"
                     srcset="/img/notifications/replacement@2.png 2x"
                     alt="date"
                     class="absolute top-1/2 -translate-y-1/2 right-0 opacity-10 w-100px -z-1"
                  />
                  <div class="h-2px gradient-lava-700 absolute left-8px right-8px bottom-0 opacity-30 rounded-full"></div>
               </router-link>
            </Transition>
         </template>
      </div>
   </Teleport>
</template>
